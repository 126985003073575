/** @format */

import React from 'react';
import { Container, Flex, Image } from '@chakra-ui/react';
import './Header.css';
import 'animate.css';
// import proImg from '../../../assets/images/GIN1.png'

// const showImage = false
// setTimeout(() => {
//   showImage = true
// }, 2000);

function Header({ product }) {
  let link;
  if (product) {
    link = product.imageLink;
  } else {
    link =
      'https://res.cloudinary.com/dj6eghdzu/image/upload/v1647840189/GIN%20IMAGES/NavyStrength_idglgj.png';
  }
  return (
    <Flex alignItems={'center'} alignSelf={'center'} justifyContent={'center'}>
      <Flex className='outerLayout' mt={'-135%'} ms={'-10'}>
        <Flex
          className='water animate__animated animate__fadeInDown animate__slower'
          justifyContent={'center'}
        >
          <div style={{ width: '70%', height: '100%' }}>
            <Image
              src={link}
              display={'none'}
              className='image animate__animated animate__fadeInUp animate__delay-2s '
              w={'100%'}
              h={'100%'}
              zIndex={5}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Header;

const Card = {
  baseStyle: {
    p: '42px',
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    position: 'relative',
    minWidth: '0px',
    wordWrap: 'break-word',
    backgroundClip: 'border-box',
  },
  variants: {
    panel: (props) => ({
      // bg: props.colorMode === 'light' ? 'linear-gradient(180deg, rgba(19, 123, 123, 0.47) 0%, rgba(18, 19, 18, 0.4653) 100%)' : 'linear-gradient(180deg, rgba(19, 123, 123, 0.47) 0%, rgba(18, 19, 18, 0.4653) 100%)' ,
      /* From https://css.glass */
      bg: 'rgba(255, 255, 255, 0.15)',
      borderRadius: '16px',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(5.1px)',
      webkitBackdropFilter: 'blur(5.1px)',
      border: '1px solid rgba(255, 255, 255, 0.04)',
      mixBlendMode: 'normal',
      width: '100%',
      // boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
      // boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
      // borderRadius: '15px',
      // backdropFilter: 'blur(8.5px)',
      // backdropBlur:'9px',
      // webkitBackdropFilter: 'blur(8.5px)',
    }),
  },
  defaultProps: {
    variant: 'panel',
  },
};

export const CardComponent = {
  components: {
    Card,
  },
};

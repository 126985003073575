import axios from 'axios';
import { server } from '../../config/index';

function AddGeoLocation(locationData) {
  axios
    .post(`${server}/api/latestlocation`, locationData)
    .then((res) => {
      console.log('Location Added Successfully');
      return res.data;
    })
    .catch((err) => console.error(err));
  // addLocation(
  //   productId,
  //   productName,
  //   location,
  //   city,
  //   countryCode,
  //   region,
  //   subRegion
  // );
  return true;
}
// function addLocation(
//   locationData
// ) {
//   const data = axios
//     .post(`${server}/api/latestlocation/add`, {
//       productId,
//       productName,
//       location,
//       city,
//       countryCode,
//       region,
//       subRegion,
//     })
//     .then((res) => {
//       console.log('Location Updated Successfully');
//       return res.data;
//     })
//     .catch((err) => console.error(err));
// }
export default AddGeoLocation;

import React from 'react';
// chakra imports
import {
  Table,
  Thead,
  Tbody,
  Button,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  HStack,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import { FaSort } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';

// Global filter
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  // eslint-disable-next-line no-unused-vars
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // Global filter ui
  return (
    <Flex
      justifyContent={{ base: 'left', md: 'right' }}
      mt={{ base: 8, md: -2, lg: -2, xl: -4, '2xl': -8 }}
    >
      <Flex w={{ base: 'full', md: '40%' }} m={2}>
        <InputGroup>
          <InputLeftElement ml={4} height='full' size={'20px'}>
            <AiOutlineSearch />
          </InputLeftElement>
          <Input
            mx={'15px'}
            maxHeight={'45px'}
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            _placeholder={{ color: 'blackAlpha.600' }}
            placeholder='search...'
            color={'brand.dark'}
            bg={'whiteAlpha.300'}
            borderRadius={'36px'}
            borderColor={'whiteAlpha.200'}
          />
        </InputGroup>
      </Flex>
    </Flex>
  );
}

const TableElement = ({ columns, data }) => {
  const textColor = 'brand.dark';
  // column filter
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // column filter ui
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <Input
        maxHeight={'35px'}
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { sortBy: [{ desc: true }], pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Flex w={'100%'} direction={'column'} mt={'-3%'} px={4} gap={2}>
      {/* Global filter */}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        // globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Flex
        w='100%'
        backgroundColor={'transparent'}
        p={6}
        borderBottomRadius={10}
      >
        {/* Table  */}
        <TableContainer w={'full'} mt={'4px'} bg={'brand.card'}>
          <Table
            color={textColor}
            w={'full'}
            px={2}
            {...getTableProps()}
            size='sm'
            variant='simple'
            border={'1px solid rgba(255, 255, 255, 0.88)'}
            borderRadius={'none'}
          >
            <Thead>
              {headerGroups.map((group, index) => (
                <Tr
                  key={index}
                  {...group.getHeaderGroupProps()}
                  border={'1px solid rgba(255, 255, 255, 0.88)'}
                >
                  {group.headers.map((column, index) => (
                    <Th
                      textTransform={'capitalize'}
                      fontWeight={'600'}
                      bg={'brand.dark'}
                      key={index}
                      fontFamily={'san serif'}
                      fontSize={18}
                      color={'brand.white'}
                      border={'1px solid rgba(255, 255, 255, 0.88)'}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      py={5}
                    >
                      <Flex alignItems={'center'}>
                        {' '}
                        {column.render('Header')}{' '}
                        {column.isSorted ? <FaSort /> : ''}
                      </Flex>
                      {/* column filter */}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    key={i}
                    {...row.getRowProps()}
                    fontSize={16}
                    bg='brand.white'
                    marginBlock={1}
                    color={'white'}
                    // borderBottom={'14px solid #d0edf2'}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <Td
                          key={i}
                          py={3}
                          {...cell.getCellProps()}
                          border={'1px solid rgba(255, 255, 255, 0.88)'}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          {/* Pagination */}
          <HStack
            spacing={'100px'}
            justifyContent={'space-between'}
            mt={'20px'}
            className='pagination'
          >
            <HStack fontSize={15}>
              <Button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                bg={'whiteAlpha.300'}
              >
                {'<<'}
              </Button>{' '}
              <Button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                bg={'whiteAlpha.300'}
              >
                {'<'}
              </Button>{' '}
              <Button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                bg={'whiteAlpha.300'}
              >
                {'>'}
              </Button>{' '}
              <Button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                bg={'whiteAlpha.300'}
              >
                {'>>'}
              </Button>{' '}
              <Text color={textColor}>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageCount}
                </strong>{' '}
              </Text>
            </HStack>
            <HStack fontSize={16}>
              <Text color={textColor}>
                Go to page:{' '}
                <Input
                  type='number'
                  defaultValue={pageIndex + 1}
                  bg={'whiteAlpha.300'}
                  borderColor={'whiteAlpha.200'}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  style={{ width: '100px' }}
                />
              </Text>{' '}
              <Select
                bg={'whiteAlpha.300'}
                borderColor={'whiteAlpha.200'}
                color={textColor}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Select>
            </HStack>
          </HStack>
        </TableContainer>
      </Flex>
    </Flex>
  );
};

export default TableElement;

import React from 'react';
import './SocialLinks.css';
import { Text, Flex, Link, Box } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

let year = 1900 + new Date().getYear();

function SocialLinks() {
  return (
    <>
      <Flex
        justifyContent={'center'}
        className='animate__animated animate__fadeInUp animate__delay-2s animate__slower'
      >
        <Box justifyContent={'center'}>
          <Text fontWeight='bold' color={'black'} mt={'20px'} fontSize={'20px'}>
            Connect with us
          </Text>
          <Link
            textDecoration='none'
            className='linkedin'
            href='https://uk.linkedin.com/in/duncan-morrison-b03463213'
            isExternal
          >
            {' '}
            <FaLinkedin size={'22px'} />
          </Link>
          <Link
            textDecoration='none'
            className='facebook'
            href='https://www.facebook.com/roehillspringsgin/'
            isExternal
          >
            {' '}
            <FaFacebook size={'22px'} />
          </Link>
          <Link
            textDecoration='none'
            className='instagram'
            href='https://www.instagram.com/roehillspringsgin/'
            isExternal
          >
            {' '}
            <FaInstagram size={'22px'} />
          </Link>
          <Link
            textDecoration='none'
            className='twitter'
            href='https://twitter.com/RoehillS'
            isExternal
          >
            {' '}
            <FaTwitter size={'22px'} />
          </Link>
        </Box>
      </Flex>

      <Text textAlign={'center'} mt={15} pt={10}>
        &copy; {year} Powered by{' '}
        <Link
          textDecoration={'none'}
          href='https://www.trackgenesis.com'
          isExternal
          fontWeight={'bold'}
        >
          TrackGenesis.
        </Link>
      </Text>
    </>
  );
}

export default SocialLinks;

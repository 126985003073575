import React from 'react';
import './Introduction.css';
import { Flex, Text } from '@chakra-ui/react';

const Introduction = (prop) => {
  let product = prop.product;
  return (
    <Flex direction={'column'} marginTop={20}>
      <Flex className='intro_card' mx={10}>
        {/* <img src={contentimg} alt='content_img' /> */}
        <Flex className='transbox' mt={5}>
          <Text className='intro_content'>{product.productDesc}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Introduction;

import {
  Button,
  Flex,
  useDisclosure,
  Text,
  Tooltip,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Textarea,
  chakra,
  FormErrorMessage,
} from '@chakra-ui/react';
import { RiDeleteBin6Line, RiBallPenFill } from 'react-icons/ri';

// import BootstrapTable from 'react-bootstrap-table-next';
import Card from '../../components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { FaTrashAlt, FaEdit } from 'react-icons/fa';
import { server } from '../../config';
import TableComponent from '../../components/Table';
import MasterFile from '../../components/ProductDetails/MasterFile';
import ReadProductData from '../../components/CommonFunctions/DBCalls/ReadProductData';
import Swal from 'sweetalert2';
const ProductList = () => {
  const [products, setProducts] = useState([]);
  const logData = localStorage.getItem('tg-token');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteId, setDeleteId] = useState();
  // modal static variables
  // const [submit1, setSubmit1] = useState(false);
  // const [submit2, setSubmit2] = useState(false);
  // const [submit3, setSubmit3] = useState(false);
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [imageLink, setImageLink] = useState('');
  let id;
  let logDetails = JSON.parse(logData);
  const [inputFields, setInputFields] = useState([
    {
      processName: '',
      processDescription: '',
      imgLink: '',
      videoLink: '',
    },
  ]);
  useEffect(() => {
    getProducts();
  }, []);
  const getProducts = async () => {
    try {
      const productsFromServer = await ReadProductData();

      productsFromServer.data && setProducts(productsFromServer.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Column titles
  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        // accessor: (cell, row) => <ProductDetails state={cell} />,
        accessor: 'productId',
      },
      {
        Header: 'Name',
        accessor: 'productName',
      },
      {
        Header: 'Description',
        accessor: (cell, row) => (
          <Tooltip label={cell.productDesc}>
            <Text noOfLines={1} maxWidth={40}>
              {cell.productDesc}
            </Text>
          </Tooltip>
        ),
      },
      {
        Header: 'Image',
        accessor: (cell, row) => (
          <Tooltip label={cell.imageLink}>
            <Text noOfLines={1} maxWidth={40}>
              {cell.imageLink}
            </Text>
          </Tooltip>
        ),
      },
      {
        id: 'actions',
        Header: () => (
          <Text pl={{ xl: '20px' }} w={'full'}>
            Actions
          </Text>
        ),
        accessor: (cell, row) => (
          <Flex direction={'row'} height={6}>
            {/* mt={1} mr={4} ml={4} */}
            <Flex mt={1} mr={4} ml={4}>
              <Link
                to={`/admin/addProduct/${cell.productId}`}
                onLoad={onOpen}
                id='edit'
              >
                <RiBallPenFill size={'20px'} />
              </Link>
            </Flex>
            {/* mt={'-3%'} */}
            <Flex mt={'-5%'}>
              <Button
                style={{ backgroundColor: 'transparent', padding: '0px' }}
                //Detele() call to delete product
                onClick={() => onDelete(cell)}
                ml='10%'
              >
                <RiDeleteBin6Line color='orangered' size={'20px'} />
              </Button>
            </Flex>
          </Flex>
        ),
      },
    ],
    []
  );
  var swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success m-2',
      cancelButton: 'btn btn-danger',
    },
    buttonStyling: false,
  });

  const onDelete = async (cell) => {
    console.log(cell._id);
    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: 'You are not able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
        confirmButtonMargin: '3px',
        confirmButtonColor: 'rgb(146, 9, 9)',
        cancelButtonColor: 'rgb(3, 70, 3)',
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleDelete(cell._id);
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your file is safe :)',
            'error'
          );
        }
      });
  };

  const handleDelete = async (id) => {
    const res = await fetch(`${server}/api/products?id=${id}`, {
      // headers: { 'x-access-token': logDetails.token},
      method: 'DELETE',
    });
    const deleted = () => {
      swalWithBootstrapButtons.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      );
      window.location.pathname = '/admin/productList';
    };
    res.status === 200 ? deleted() : alert('Error Deleting This Product');
  };

  return (
    <Flex flexDirection={'column'}>
      <Card width='100%'>
        <MasterFile />
        <Flex>
          {products?.length !== 0 ? (
            <TableComponent columns={columns} data={products} />
          ) : (
            <Text
              color={'#230B0C'}
              mt={'10%'}
              ml={'5%'}
              fontWeight={'bold'}
              fontSize={20}
            >
              No Produt Data Available.
            </Text>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default ProductList;

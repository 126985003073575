import axios from 'axios';
import React, { useState } from 'react';
import { bcuri, tag_name } from '../../config/index';
import Swal from 'sweetalert2';
import {
  Flex,
  FormControl,
  Stack,
  FormLabel,
  Input,
  Button,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Modal,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import UpdateBlockchainData from '../../components/CommonFunctions/BlockchainCalls/UpdateBlockchainData';
import CreateBlockchainData from '../../components/CommonFunctions/BlockchainCalls/CreateBolockchainData';
import CreateProductInfo from '../../components/CommonFunctions/DBCalls/CreatProductInfo';
const TrackOrigin = (props) => {
  let batchid = props.setBatchId;
  let [batchId, setBatchId] = useState(batchid);
  const [inputList, setInputList] = useState([
    { parameterName: '', value: '' },
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ModalStatus, setModalStatus] = useState(false);
  const initialRef = React.useRef();
  //Parameter List Creation
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: '', value: '' }]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!batchId) {
      alert('Enter Batch Id');
      return;
    }
    let cStatus = await CreateProductInfo(batchId, inputList);
    let createStatus = CreateBlockchainData(batchId, inputList);
    if (createStatus) {
      Swal.fire('Information Added to Blockchain Successfully!');
    } else {
      Swal.fire('Error : Batch Id Already Exists');
    }
    handleClose();
  };
  const updateData = async (e) => {
    e.preventDefault();
    let cStatus = await CreateProductInfo(batchId, inputList);
    let updateStatus = UpdateBlockchainData(batchId, inputList);
    if (updateStatus) {
      Swal.fire('Information Updated to Blockchain Successfully!');
    } else {
      Swal.fire('Error : Something Went Wrong');
    }
    handleClose();
  };

  const handleClick = () => {
    setModalStatus(true);
  };

  const handleClose = () => {
    setModalStatus(false);
  };
  return (
    <>
      {/* <Button onClick={onOpen} bg={'#1DD8CF'} shadow='0px 4px 8px rgba(0, 0, 0, 0.5)' w={{ base: '100%', md: '25%' }} m={5}> Add Details</Button> */}
      {!props.setBatchId ? (
        <Button
          onClick={handleClick}
          class='bn632-hover bn26'
          w={{ base: '100%', md: '25%' }}
          m={3}
        >
          {' '}
          Add Details
        </Button>
      ) : (
        <Button
          onClick={handleClick}
          class='bn632-hover bn26'
          w={{ base: '100%', md: '25%' }}
          m={3}
        >
          {' '}
          Add Stage
        </Button>
      )}
      <Modal
        initialFocusRef={initialRef}
        isOpen={ModalStatus}
        onClose={handleClose}
        size={'3xl'}
      >
        <ModalOverlay />
        <ModalContent bg={'#CCD1E4'}>
          <ModalHeader>Product Details To Blockchain</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={14}>
            <Flex align={'center'} justify={'center'}>
              <Card
                justifyContent={'center'}
                bg={'linear-gradient(315deg, #1b2845 0%, #274060 74%)'}
              >
                <Stack py={1} px={1} justifyContent={'center'}>
                  <Stack>
                    <Flex alignItems='center' justifyContent='start'>
                      <Flex
                        direction={{ base: 'column', md: 'column' }}
                        w='100%'
                        background='transparent'
                        p={{ base: '8px', md: '28px', xl: '0' }}
                      >
                        <Flex
                          direction={{ base: 'column', md: 'row' }}
                          w='100%'
                          justifyContent={'space-between'}
                          background='transparent'
                          marginBottom={'4'}
                        >
                          <FormControl
                            variant='floating'
                            id='batchId'
                            mr={3}
                            mb={{ base: '6px', md: '0', xl: '0' }}
                            isRequired
                          >
                            <Input
                              type='text'
                              bg={'blackAlpha.100'}
                              color={'white'}
                              placeholder='Batch Id'
                              focusBorderColor={'transparent'}
                              defaultValue={batchId}
                              value={batchId}
                              onChange={(e) => setBatchId(e.target.value)}
                            />
                            <FormLabel>Batch Id</FormLabel>
                          </FormControl>
                        </Flex>
                        <Flex
                          direction={{ base: 'column', md: 'column' }}
                          w='100%'
                          justifyContent={'space-between'}
                          background='transparent'
                          marginBottom={'4'}
                        >
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Flex
                                  direction={{ base: 'column', md: 'row' }}
                                  w='100%'
                                  justifyContent={'space-between'}
                                  background='transparent'
                                  marginBottom={'4'}
                                >
                                  <FormControl
                                    variant='floating'
                                    id='parameterName'
                                    mr={2}
                                    mb={{ base: '6px', md: '0', xl: '0' }}
                                    isRequired
                                  >
                                    <Input
                                      type='text'
                                      bg={'blackAlpha.100'}
                                      color={'white'}
                                      name='parameterName'
                                      focusBorderColor={'transparent'}
                                      placeholder='Parameter Name'
                                      value={x.parameterName}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                    <FormLabel> Parameter Name</FormLabel>
                                  </FormControl>

                                  <FormControl
                                    variant='floating'
                                    id='parameterValue'
                                    mr={2}
                                    mb={{ base: '6px', md: '0', xl: '0' }}
                                    isRequired
                                  >
                                    <Input
                                      bg={'blackAlpha.100'}
                                      focusBorderColor={'transparent'}
                                      color={'white'}
                                      name='value'
                                      type='text'
                                      placeholder='Parameter Value'
                                      value={x.value}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                    <FormLabel>Parameter Value</FormLabel>
                                  </FormControl>

                                  {inputList.length !== 1 && (
                                    <Button
                                      onClick={() => handleRemoveClick(i)}
                                      class='bn632-hover bn26'
                                      w={{ base: '100%', md: '20%' }}
                                      style={{ marginRight: '5px' }}
                                    >
                                      Remove
                                    </Button>
                                  )}
                                  {inputList.length - 1 === i && (
                                    <Button
                                      class='bn632-hover bn26'
                                      w={{ base: '100%', md: '20%' }}
                                      onClick={handleAddClick}
                                    >
                                      Add
                                    </Button>
                                  )}
                                </Flex>
                              </>
                            );
                          })}
                        </Flex>
                        {!props.setBatchId ? (
                          <Button
                            type='button'
                            onClick={onSubmit}
                            class='bn632-hover bn26'
                            w={{ base: '100%', md: '45%' }}
                            m={3}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            type='button'
                            onClick={updateData}
                            class='bn632-hover bn26'
                            w={{ base: '100%', md: '45%' }}
                            m={3}
                          >
                            Update
                          </Button>
                        )}
                      </Flex>
                    </Flex>

                    {/* <ToastContainer transition={Bounce} /> */}
                  </Stack>
                </Stack>
              </Card>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TrackOrigin;

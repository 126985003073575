// Chakra imports
import { Flex, StatDownArrow, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../config/index';
import PieCharts from './PieChart';
import Card from '../../components/Card/Card';
export default function Dashboard() {
  const [serverData, setServerData] = useState([]);
  const [moreProduct, setMoreProduct] = useState([]);
  const getLocation = async () => {
    await axios
      .get(`${server}/api/latestlocation/sort`, {
        timeout: 6000,
      })
      .then((res) => {
        console.log(res);
        setServerData(res.data.result);
      })
      .catch((err) => console.error(err));
  };
  const getMoreProductAnalysis = async () => {
    let data = await axios
      .get(`${server}/api/analysis`, {
        timeout: 6000,
      })
      .then((res) => {
        setMoreProduct(res.data.products);
        return res.data;
      })
      .catch((err) => console.error(err));
    return data;
  };
  useEffect(() => {
    getMoreProductAnalysis();
    getLocation();
    return () => {
      console.log('This will be logged on unmount');
    };
  }, []);

  window.google.charts.load('current', {
    packages: ['geochart', 'corechart', 'controls'],
    // Note: you will need to get a mapsApiKey for your project.
    // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings (trackgenesis@gmail.com)

    mapsApiKey: 'AIzaSyCjjg3Bo0gdOEidwnlyaENT_4vpSkNX1IE',
  });
  window.google.charts.setOnLoadCallback(drawGeoMap);
  function drawGeoMap() {
    var data = new window.google.visualization.DataTable();
    data.addColumn('string', 'City');
    data.addColumn('number', 'User');

    serverData.map((sdata, i) =>
      // console.log(sdata)
      data.addRow([sdata._id?.cityCountry || sdata._id?.city, sdata.count])
    );
    var options = {
      // region: '142',
      displayMode: 'markers',
      colorAxis: { colors: ['#FF5600', '#141E61'] },
      backgroundColor: '#42b5eb',
      datalessRegionColor: '#BEDCFA',
      defaultColor: '#F5F5F5',
    };
    // #D0E8F2
    var chart = new window.google.visualization.GeoChart(
      document.getElementById('geo_chart')
    );
    chart.draw(data, options);
  }
  return (
    <Flex flexDirection={'row'} width={'100%'} gap={4} p={5}>
      <Flex flexDirection='column' width={'65%'}>
        <Text
          color={'whiteAlpha.900'}
          textShadow={'0px 4px 8px rgba(0, 0, 0, 0.5)'}
          letterSpacing={1}
          // bgGradient={'linear-gradient(180deg, #0075AD 3%, rgb(70, 170, 88) 95%)'} bgClip='text'
          fontSize='2xl'
          fontWeight='extrabold'
          mb={8}
        >
          {' '}
          CUSTOMER LOCATION
        </Text>
        <Flex
          id='geo_chart'
          style={{
            position: 'relative',
            height: '60vh',
            width: '48vw',
            border: '3px #000 solid',
          }}
        ></Flex>
      </Flex>
      <Flex direction={'column'} width={'35%'}>
        {/* <Card ml={2} width={'100%'} height='100%'> */}
        <Text
          color={'whiteAlpha.900'}
          textShadow={'0px 4px 8px rgba(0, 0, 0, 0.5)'}
          letterSpacing={1}
          // bgGradient={'linear-gradient(180deg, #0075AD 3%, rgb(70, 170, 88) 95%)'} bgClip='text'
          fontSize='2xl'
          fontWeight='extrabold'
          mb={6}
        >
          {' '}
          Most Clicked Products{' '}
        </Text>
        <Flex>
          {moreProduct.length > 0 ? (
            <PieCharts moreProducts={moreProduct} />
          ) : (
            'No Data Available'
          )}
          {/* {moreProduct ?  <BootstrapTable bootstrap4 keyField='id' data={moreProduct} columns={columns}  defaultSorted={ defaultSorted } /> :"Product List is Empty"}  */}
        </Flex>
        {/* </Card> */}
        {/* {moreProduct ?  <BootstrapTable bootstrap4 keyField='id' data={moreProduct} columns={columns}  defaultSorted={ defaultSorted } /> :"Product List is Empty"}  */}
      </Flex>
    </Flex>
  );
}

import axios from 'axios';
import { pbcuri, pbcapi_key } from '../../../config';
var prod;
async function ReadBlockchainData(batchId) {
  const header = {
    'content-type':
      'application/json, text/plain, application/x-www-form-urlencoded',
    headers: { Authorization: `Bearer ${pbcapi_key}` },
  };
  const serverdata = await axios
    .get(`${pbcuri}/readAsset/?batchId=${batchId}`, header)
    .then((res) => {
      var info = res;
      //return the serverdata
      return info;
    })
    .catch((err) => {
      console.log(err);
      console.log('Error: BlockChain Data Unavilable');
      return 400;
    });
  const data = await serverdata;
  return data;
}
export default ReadBlockchainData;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Flex,
  Text,
  Image,
  Link,
} from '@chakra-ui/react';
import logo from '../../assets/images/roehilogo.png';
import { uri } from '../../config';
import { FaLinkedinIn } from 'react-icons/fa';
function AgeTracker() {
  let query = new URLSearchParams(useLocation().search);
  var hyperLink = query.get('hyperLink');
  const finalRef = React.useRef();
  const [status, setStatus] = useState(false);
  const [ModalStatus, setModalStatus] = useState(true);
  var windowObjectReference = null; // global variable
  var PreviousUrl;

  const handleClose = () => {
    setModalStatus(false);
    // if(windowObjectReference == null || windowObjectReference.closed) {
    //   windowObjectReference = window.open(hyperLink, 'SingleSecondaryWindowName',
    //        'popup');
    // } else if(PreviousUrl != hyperLink) {
    //   windowObjectReference = window.open(hyperLink, 'SingleSecondaryWindowName',
    //     'popup');
    //   /* if the resource to load is different,
    //      then we load it in the already opened secondary window and then
    //      we bring such window back on top/in front of its parent window. */
    //   windowObjectReference.focus();
    // } else {
    //   windowObjectReference.focus();
    // };
    // PreviousUrl = hyperLink;

    // window.location.href=`${uri}/product`
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        size='md'
        isOpen={ModalStatus}
        onClose={handleClose}
      >
        <ModalOverlay bg={'#494845'} />
        <ModalContent alignSelf={'center'} mx={15}>
          {!status ? (
            <ModalBody bgColor='white'>
              <Flex
                Width='100'
                height='210'
                direction={'column'}
                bgColor='white'
                justifyContent={'center'}
              >
                <Flex width='100%' direction={'row'} justifyContent={'center'}>
                  <Flex
                    width='50%'
                    height='90%'
                    justifyContent={'left'}
                    ml={25}
                  >
                    <Image
                      src={logo}
                      alt='Dan Abramov'
                      bgColor={'black'}
                      borderRadius={100}
                      width='110px'
                      height={'110px'}
                    />
                  </Flex>
                  <Flex
                    width='100%'
                    height='100%'
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Text
                      textAlign={'center'}
                      fontWeight={'bold'}
                      fontSize={18}
                      fontFamily={'charcuterie-block'}
                    >
                      AGE VERIFICATION
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  width='100%'
                  height='0px'
                  mt={5}
                  border='1px solid rgba(0, 0, 0, 0.3)'
                  boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
                >
                  {' '}
                </Flex>
              </Flex>
              <Flex direction={'row'} width={'100%'} justifyContent={'center'}>
                <Text color={'blackAlpha.600'} fontSize={18}>
                  You must be <span style={{ color: 'red' }}>18</span> years old
                  to Enter
                </Text>
              </Flex>
              <Flex
                justifyContent={'center'}
                m={10}
                mb={'10%'}
                direction={{ base: 'column', md: 'row' }}
              >
                <Link
                  href={hyperLink}
                  style={{ textDecoration: 'none', color: 'black' }}
                  isExternal
                  bgColor={'#32cd32'}
                  m={5}
                  mb={{ base: 5, md: 0 }}
                  mr={{ base: 0, md: 5 }}
                  border='none'
                  w={'100%'}
                  textAlign='center'
                  boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
                  fontSize={22}
                >
                  Yes
                </Link>
                <Button
                  bgColor={'#FBE5E5'}
                  m={5}
                  onClick={() => {
                    setStatus(true);
                  }}
                  border='none'
                  w={'100%'}
                  boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
                  fontSize={22}
                >
                  No
                </Button>
              </Flex>
            </ModalBody>
          ) : (
            <ModalBody>
              <Flex
                width='100'
                height='150'
                direction={'column'}
                bgColor='white'
                justifyContent={'center'}
              >
                <Flex
                  direction={'row'}
                  width={'100%'}
                  justifyContent={'center'}
                >
                  <Text color={'black'} fontSize={40}>
                    Sorry...
                  </Text>
                </Flex>
                <Flex
                  width='225'
                  height='0px'
                  mt={1}
                  border='1px solid rgba(0, 0, 0, 0.3)'
                  boxShadow='0px 1px 1px rgba(0, 0, 0, 0.5)'
                >
                  {' '}
                </Flex>
                <Flex
                  direction={'row'}
                  m={2}
                  width={'100%'}
                  justifyContent={'center'}
                >
                  <Text color={'blackAlpha.700'} fontSize={14}>
                    You are not old enough to view the site ...
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
export default AgeTracker;

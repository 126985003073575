import axios from 'axios';
import React, { useState } from 'react';

import { bcuri } from '../../config';
import Card from '../../components/Card/Card';
import { Flex, Stack, UnorderedList, ListItem } from '@chakra-ui/react';
import TrackProduct from '../../components/ProductDetails/TrackProduct';
import TrackOrigin from '../../components/ProductDetails/TrackOrigin';
const BcTrack = () => {
  return (
    <Flex align={'center'} justify={'center'}>
      <Card justifyContent={'center'}>
        <Stack py={1} px={1} justifyContent={'center'}>
          <Stack>
            <UnorderedList color={'white'}>
              <ListItem m={4}>
                Add Product Details To Blockchain Network
              </ListItem>
              <TrackOrigin />
              <ListItem m={4}>
                View Product Details From Blockchain Network
              </ListItem>
              <TrackProduct />
            </UnorderedList>
          </Stack>
        </Stack>
      </Card>
    </Flex>
  );
};
export default BcTrack;

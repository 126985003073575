import axios from 'axios';
import { server, uri } from '../../../config';
async function ReadSingleProduct(pid) {
  const data = await axios
    .get(`${server}/api/products/single?id=${pid}`, {
      timeout: 5000,
    })
    .then((res) => {
      // console.table(res.data);
      return res.data;
    })
    .catch((err) => console.error(err));
  return data;
}
export default ReadSingleProduct;

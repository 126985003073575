import axios from 'axios';
import { bcuri, api_key } from '../../../config';
var prod;
async function ReadBlockchainData(Id) {
  const header = {
    'content-type':
      'application/json, text/plain, application/x-www-form-urlencoded',
    headers: { Authorization: `Bearer ${api_key}` },
    params: {
      Id: Id,
    },
  };
  const serverdata = await axios
    .get(`${bcuri}/`, header)
    .then((res) => {
      var info = res;
      //return the serverdata
      return info;
    })
    .catch((err) => {
      console.log('Error: BlockChain Data Unavilable');
      return 400;
    });
  const data = await serverdata;
  return data;
}
export default ReadBlockchainData;

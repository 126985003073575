import {
  Td,
  Th,
  Flex,
  Table,
  Tr,
  Tbody,
  Text,
  Thead,
  TableCaption,
  Img,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
// import ReadBlockchainData from '../../../CommonFunctions/BlockchainCalls/ReadBlockchainData';
import ReadProductInfoData from '../../../CommonFunctions/DBCalls/ReadBlockchainData';
const ProductInfo = (prop) => {
  let [blockchainData, setBlockchainData] = useState([]);
  var prodata = '';
  let assetId = prop.assetId;
  useEffect(() => {
    getBlock();
  }, []);

  const getBlock = async () => {
    prodata = await ReadProductInfoData(assetId);
    if (prodata?.data[0]?.data) {
      setBlockchainData(prodata?.data[0]?.data);
    } else {
      console.log('No Data Avilable');
    }
  };
  return (
    <>
      {blockchainData.length > 0 ? (
        <>
          <Flex direction={'column'}>
            <Text className='aboutus'>Product Info </Text>
            <Flex className='line'> </Flex>
            <Flex marginTop={'10%'} w={'100%'} justifyContent={'center'}>
              <Table
                variant='simple'
                boxShadow={'2px 4px 4px rgba(0, 0, 0, 0.5)'}
                w={'90%'}
              >
                <Tbody justifyItems={'center'} p={'10px'}>
                  {blockchainData.length > 0 &&
                    blockchainData.map((data, j) => (
                      <>
                        {data.inputList
                          ? data.inputList.map((list, i) => (
                              <>
                                <Tr justifyContent={'center'} key={i}>
                                  <Th
                                    p={'10px'}
                                    bgColor={'#0F2369'}
                                    color='white'
                                  >
                                    {list.parameterName !== '' &&
                                      list?.parameterName}
                                  </Th>
                                  <Td
                                    p={'10px'}
                                    bgColor={'#E8E8E8'}
                                    color='black'
                                  >
                                    {list.value !== '' && list?.value}
                                  </Td>
                                </Tr>
                              </>
                            ))
                          : Array.isArray(data) &&
                            data.map((param, i) =>
                              param?.parameterName || param?.parameterValue ? (
                                <Tr justifyContent={'center'} key={i}>
                                  <Th
                                    p={'10px'}
                                    bgColor={'#0F2369'}
                                    color='white'
                                  >
                                    {param.parameterName}
                                  </Th>
                                  <Td
                                    p={'10px'}
                                    bgColor={'#E8E8E8'}
                                    color='black'
                                  >
                                    {param.parameterValue}
                                  </Td>
                                </Tr>
                              ) : param?.parameterName === '' ||
                                param?.parameterValue === '' ? (
                                ''
                              ) : (
                                <Tr justifyContent={'center'} key={i}>
                                  <Th
                                    p={'10px'}
                                    bgColor={'#0F2369'}
                                    color='white'
                                  >
                                    Water Flow -{j++}
                                  </Th>
                                  <Td
                                    p={'10px'}
                                    bgColor={'#E8E8E8'}
                                    color='black'
                                  >
                                    {param}
                                  </Td>
                                </Tr>
                              )
                            )}
                      </>
                    ))}
                </Tbody>
              </Table>
            </Flex>
            <Text alignSelf={'flex-end'} mr={20} mt={5}>
              <b className='me-1' color='black'>
                BLOCKCHAIN
              </b>
              <span color='gray'> Powered</span>
            </Text>
          </Flex>
        </>
      ) : null}
    </>
  );
};

export default ProductInfo;

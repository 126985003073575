import {
  Td,
  Th,
  Flex,
  Table,
  Tr,
  Tbody,
  Text,
  Thead,
  TableCaption,
  Img,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReadBlockchainfromPublic from '../../CommonFunctions/BlockchainCalls/ReadBlockchianfromPublic';

const ProductInfoFromPBC = ({ blockData }) => {
  return (
    <>
      {blockData && blockData.length > 0 ? (
        <>
          <Flex direction={'column'}>
            <Text className='aboutus'>Product Info </Text>
            <Flex className='line'> </Flex>
            <Flex marginTop={'10%'} w={'100%'} justifyContent={'center'}>
              <Table
                variant='simple'
                boxShadow={'2px 4px 4px rgba(0, 0, 0, 0.5)'}
                w={'90%'}
              >
                <Tbody justifyItems={'center'} p={'10px'}>
                  {blockData &&
                    blockData?.length >= 1 &&
                    blockData?.map((data, i) => (
                      <React.Fragment key={i}>
                        {Array.isArray(data)
                          ? data.map((produc, j) => (
                              <React.Fragment key={j}>
                                <Tr justifyContent={'center'} key={i}>
                                  <Th
                                    p={'10px'}
                                    bgColor={'#0F2369'}
                                    color='white'
                                  >
                                    {produc[0]}
                                  </Th>
                                  <Td
                                    p={'10px'}
                                    bgColor={'#E8E8E8'}
                                    color='black'
                                  >
                                    {typeof produc[1] === 'string' &&
                                    isNaN(produc[1])
                                      ? produc[1]
                                      : parseFloat(produc[1]).toFixed(2)}
                                  </Td>
                                </Tr>
                              </React.Fragment>
                            ))
                          : null}
                      </React.Fragment>
                    ))}
                </Tbody>
              </Table>
            </Flex>
            <Text alignSelf={'flex-end'} mr={20} mt={5}>
              <b className='me-1' color='black'>
                BLOCKCHAIN
              </b>
              <span color='gray'> Powered</span>
            </Text>
          </Flex>
        </>
      ) : null}
    </>
  );
};

export default ProductInfoFromPBC;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Doughnut, Pie, Chart } from 'react-chartjs-2';
import { chartColors } from './colors';
import './MainPage.css';
import { ArcElement } from 'chart.js';
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';
import { Flex } from '@chakra-ui/react';

function PieCharts({ moreProducts }) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  let chartInstance = null;
  let [moreProduct, setMoreProduct] = useState(moreProducts);
  const options = {
    legend: {
      display: true,
      position: 'bottom',
    },
    tooltips: {
      enabled: true,
    },
  };

  // console.log(moreProduct[0].productName,moreProduct[0].productCount)
  let data,
    label = [],
    prodData = [],
    i = 0;
  {
    moreProduct &&
      moreProduct.map(
        (prod, i) => (
          // {moreProduct.length>0  && moreProduct.forEach(prod => {
          // console.log(prod.Count)
          label.push(prod.productName), prodData.push(prod.Count)
          // console.log(label +"hai");
        )
      );
  }

  data = {
    maintainAspectRatio: false,
    responsive: true,
    labels: label,
    datasets: [
      {
        labels: 'Count',
        data: prodData,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };

  return (
    <Flex
      style={styles.relative}
      width={{ md: 260, xl: 380 }}
      top={{ md: 25, xl: '2%' }}
      alignSelf={'center'}
      justifySelf={'center'}
      height={{ md: 405, xl: 445 }}
    >
      <Doughnut data={data} options={options} width={'200%'} height={'380%'} />

      <Flex id='legend' />
    </Flex>
  );
}

const styles = {
  relative: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.18)',
    boxShadow: '10px 4px 4px rgba(0, 0, 0, 0.25)',
    paddingLeft: '5%',
    paddingRight: '1%',
    paddingTop: '2%',
    paddingBottom: '3%',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 15,
  },
};

export default PieCharts;

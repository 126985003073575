import axios from 'axios';
import { server, uri } from '../../../config';
async function ReadProductInfoData(assetId) {
  let data = await axios
    .get(`${server}/api/blockchaindata/single?id=${assetId}`, {
      timeout: 5000,
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => console.error(err));
  return data;
}
export default ReadProductInfoData;

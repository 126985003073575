// import
import { SiHiveBlockchain } from 'react-icons/si';
import { MdListAlt, MdPrint, MdHistoryEdu, MdLocationOn } from 'react-icons/md';
import { HomeIcon, StatsIcon } from './components/Icons/Icons';

var dashRoutes = [
  {
    path: '/productList',
    name: 'Product List',
    icon: <MdListAlt color='inherit' />,
    layout: '/admin',
  },

  {
    path: '/bcTrack',
    name: 'Blockchain Data',
    icon: <SiHiveBlockchain color='inherit' />,
    layout: '/admin',
  },

  {
    path: '/printQR',
    name: 'Print QR',
    icon: <MdPrint color='inherit' />,
    layout: '/admin',
  },
  {
    path: '/history',
    name: 'Customer Clicks',
    icon: <MdHistoryEdu color='inherit' />,
    layout: '/admin',
  },
];
export default dashRoutes;

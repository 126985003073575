import axios from 'axios';
import { server, uri } from '../../../config';
async function UpdateProductData(
  Id,
  Name,
  Brand,
  Desc,
  imageLink,
  processList
) {
  let productId = Id;
  let productName = Name.toLowerCase().trim();
  let productBrand = Brand.toLowerCase().trim();
  let productDesc = Desc.trim();

  const data = await axios
    .put(
      `${server}/api/products?id=${Id}`,
      {
        productId,
        productName,
        productBrand,
        productDesc,
        imageLink,
        processList,
      }
      // {
      //   headers: { 'x-access-token': logDetails.token },
      // }
    )
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
  const product = await data;
  // console.log(product);
  return false;
}

export default UpdateProductData;

import React from 'react';
import {
  Center,
  Flex,
  Box,
  Text,
  Image,
  SimpleGrid,
  Link,
  Img,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import './Similar.css';
import gin1 from '../../../assets/images/Honey-Berry.png';
import gin2 from '../../../assets/images/gin2.png';
import drop from '../../../assets/images/drop1.png';
import NavyStrength from '../../../assets/images/NavyStrength.png';
import blueGin from '../../../assets/images/blue.png';
import { uri } from '../../../config';
import handleMoreProduct from '../../CommonFunctions/DBCalls/handleMoreProduct';
function similarProduct(props) {
  let location = props.location;
  let GinImages = {
    pro1: 'https:roehillsprings.com/wp-content/uploads/2020/09/1-1.png',
    pro2: 'https:roehillsprings.com/wp-content/uploads/2021/11/Honey-Berry.png',
  };
  const handleRedirect = (relatedProductName, hyperLink) => {
    handleMoreProduct(relatedProductName, location);
    // window.location.href = `${uri}/age?hyperLink=${hyperLink}`;
  };
  return (
    <Flex direction={'column'} marginTop={10}>
      <Text className='aboutus'>Related Products</Text>
      <Flex className='lines'></Flex>
      <SimpleGrid columns={2} spacing={1} marginTop={17}>
        <Button
          textDecoration='none'
          border={'none'}
          onClick={() =>
            handleRedirect(
              'Honeyberry Gin (20cl)',
              'https://roehillsprings.com/product/honeyberry-gin-20cl/'
            )
          }
          data-aos='fade-up'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <Center>
            {' '}
            <Box className='moreproduct' backgroundImage={`url(${drop})`}>
              {/* <Image src= {drop} /> */}
              <Box justifyContent={'center'} height={'80%'} className='MainImg'>
                <Image
                  borderRadius='26px'
                  w={'80%'}
                  h={'100%'}
                  ml={'4%'}
                  justifySelf={'center'}
                  src={gin1}
                />
              </Box>
              <Box className='more_title'>
                <Center fontWeight='bold' color='white'>
                  <Text textAlign={'center'}>Honeyberry Gin (20cl)</Text>
                </Center>
              </Box>
            </Box>
          </Center>
        </Button>
        <Button
          textDecoration='none'
          border={'none'}
          onClick={() =>
            handleRedirect(
              'Gin No. 5 (70cl)',
              'https://roehillsprings.com/product/roehill-springs-gin-no-5-70cl/'
            )
          }
          data-aos='fade-up'
          data-aos-duration='3000'
          data-aos-delay='200'
        >
          <Center>
            {' '}
            <Box className='moreproduct' backgroundImage={`url(${drop})`}>
              {/* <Image src= {drop} /> */}
              <Box justifyContent={'center'} height={'80%'} className='MainImg'>
                <Image
                  borderRadius='26px'
                  w={'80%'}
                  h={'100%'}
                  ml={'4%'}
                  justifySelf={'center'}
                  src={gin2}
                />
              </Box>
              <Box className='more_title'>
                <Center fontWeight='bold' color='white'>
                  <Text textAlign={'center'}> Gin No. 5 (70cl)</Text>
                </Center>
              </Box>
            </Box>
          </Center>
        </Button>

        <Button
          textDecoration='none'
          border={'none'}
          onClick={() =>
            handleRedirect(
              'NavyStrength (70cl)',
              'https://roehillsprings.com/product/roehill-springs-navy-strength-70cl/'
            )
          }
          data-aos='fade-up'
          data-aos-duration='3000'
          data-aos-delay='300'
        >
          <Center>
            {' '}
            <Box className='moreproduct' backgroundImage={`url(${drop})`}>
              {/* <Image src= {drop} /> */}
              <Box justifyContent={'center'} height={'80%'} className='MainImg'>
                <Image
                  borderRadius='26px'
                  w={'80%'}
                  h={'100%'}
                  ml={'4%'}
                  justifySelf={'center'}
                  src={NavyStrength}
                />
              </Box>
              <Box className='more_title'>
                <Center fontWeight='bold' color='white'>
                  <Text textAlign={'center'}>NavyStrength (70cl)</Text>
                </Center>
              </Box>
            </Box>
          </Center>
        </Button>

        <Button
          textDecoration='none'
          border={'none'}
          onClick={() =>
            handleRedirect(
              'Blue 50cl',
              'https://roehillsprings.com/product/roehill-springs-blue-50cl/'
            )
          }
          data-aos='fade-up'
          data-aos-duration='3000'
          data-aos-delay='300'
        >
          <Center>
            {' '}
            <Box className='moreproduct' backgroundImage={`url(${drop})`}>
              {/* <Image src= {drop} /> */}
              <Box justifyContent={'center'} height={'80%'} className='MainImg'>
                <Image
                  borderRadius='26px'
                  w={'80%'}
                  h={'100%'}
                  ml={'4%'}
                  justifySelf={'center'}
                  src={blueGin}
                />
              </Box>
              <Box className='more_title'>
                <Center fontWeight='bold' color='white'>
                  <Text textAlign={'center'}>Blue 50cl </Text>
                </Center>
              </Box>
            </Box>
          </Center>
        </Button>
      </SimpleGrid>
    </Flex>
  );
}

export default similarProduct;

// Chakra Imports
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Flex,
  Icon,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import GitHubButton from 'react-github-btn';
import { Separator } from '../Separator/Separator';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FaTwitter, FaFacebook } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();

  const handleLogout = () => {
    window.localStorage.removeItem('tg-token');
  };
  // Chakra Color Mode
  let fixedDisplay = 'flex';
  if (props.secondary) {
    fixedDisplay = 'none';
  }

  let bgButton = useColorModeValue(
    'linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)',
    'white'
  );
  let colorButton = useColorModeValue('white', 'gray.700');
  const secondaryButtonBg = useColorModeValue('white', 'transparent');
  const secondaryButtonBorder = useColorModeValue('gray.700', 'white');
  const secondaryButtonColor = useColorModeValue('gray.700', 'white');
  const settingsRef = React.useRef();
  return (
    <>
      <Popover
        isOpen={props.isOpen}
        onClose={props.onClose}
        isLazy
        lazyBehavior='keepMounted'
      >
        <PopoverContent>
          <PopoverHeader pt='24px' px='24px'>
            <PopoverCloseButton />
          </PopoverHeader>
          <PopoverBody w='340px' ps='24px' pe='40px'>
            <Flex flexDirection='column'>
              <Box
                display={fixedDisplay}
                justifyContent='space-between '
                mb='16px'
              >
                <Text fontSize='md' fontWeight='600' mb='4px'>
                  Navbar Fixed
                </Text>
                <Switch
                  colorScheme='teal'
                  isChecked={switched}
                  onChange={(event) => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box>
              <Flex
                justifyContent='space-between'
                alignItems='center'
                mb='24px'
              >
                <Text fontSize='md' fontWeight='600' mb='4px'>
                  Dark/Light
                </Text>
                <Button onClick={toggleColorMode}>
                  Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
                </Button>
              </Flex>

              <Separator />
              <Flex justifyContent='center' alignItems='center' mt='24px'>
                <NavLink to='/auth/signin'>
                  <Button onClick={handleLogout}>Log Out</Button>
                </NavLink>
              </Flex>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};

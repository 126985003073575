import React, { useState } from 'react';
import TrackList from '../../views/Dashboard/TrackList';

import {
  Flex,
  Button,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Modal,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import TrackOrigin from './TrackOrigin';

const TrackProduct = () => {
  let [updateStatus, setUpdateStatus] = useState();
  let [batchId, setBatchId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  return (
    <>
      <Button
        onClick={onOpen}
        class='bn632-hover bn26'
        w={{ base: '100%', md: '25%' }}
      >
        {' '}
        View Details
      </Button>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
        size={'3xl'}
      >
        <ModalOverlay />
        <ModalContent bg={'#CCD1E4'}>
          <ModalHeader>Product Details From Blockchain</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={14}>
            <Flex
              direction={{ base: 'column', md: 'column' }}
              w='100%'
              background='transparent'
              p={{ base: '8px', md: '28px', xl: '0' }}
            >
              <Card bg={'linear-gradient(315deg, #1b2845 0%, #274060 74%)'}>
                <TrackList
                  setBatchId={setBatchId}
                  setUpdateStatus={setUpdateStatus}
                />
                {updateStatus ? <TrackOrigin setBatchId={batchId} /> : ''}
              </Card>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TrackProduct;

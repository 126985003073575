import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Box,
  Flex,
  Image,
  Heading,
  Center,
  Input,
  Stack,
  InputRightElement,
  InputGroup,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ImEye, ImEyeBlocked } from 'react-icons/im';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import roehill from '../../assets/images/reohill.jpg';
import roehilllogo from '../../assets/images/roehilogo.png';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_VALIDATION } from './signinValid';
import { server, uri } from '../../config/index';
import Swal from 'sweetalert2';
import './Login.css';
const Login = () => {
  // functionality
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // functionality
  // validation starts
  // form Validation
  const initValues = {
    email: '',
    password: '',
  };

  const [initialValue, setinitialValue] = useState(initValues);

  const schema = yup
    .object()
    .shape({
      email: yup.string().email().required(REQUIRED_VALIDATION('⚠️ Email')),
      password: yup
        .string()
        .required(REQUIRED_VALIDATION('⚠️ Password'))
        .min(4, '⚠️ Password must be min 4 char long!'),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: initialValue,
  });

  const onSubmit = (values) => {
    login(values);
  };
  // User login
  async function login(values) {
    let data = { email: values.email, password: values.password };
    const serverdata = await axios
      .post(`${server}/api/users/login`, data, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire('Successfully LoggedIn!', 'success');

          return res.data;
        } else {
          Swal.fire('Invalid Credentials!', 'error');
          window.location.href = `${uri}/`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const AdminData = await serverdata;

    localStorage.setItem(
      'tg-token',
      JSON.stringify({
        token: AdminData.token,
        userName: AdminData.userName,
      })
    );
    window.location.href = ` ${uri}/admin`;
  }
  // validation ends
  let [status, setStatus] = useState(false);
  const handleDisplay = () => {
    setStatus(true);
  };
  const handleClose = () => {
    setStatus(false);
  };
  return (
    <Flex
      backgroundColor='#041142'
      h={'100vh'}
      w={'100%'}
      justifyContent={'center'}
      alignContent={'center'}
    >
      <Flex
        class='main'
        justifyContent={'center'}
        alignItems={'center'}
        direction={'row'}
      >
        {/* <Box marginTop='-1%' height='660px' width='1680px' display='flex'> */}
        <Flex
          direction={'column'}
          justifyContent={'center'}
          width={status ? 650 : 850}
          style={{ transition: 'width 1s' }}
        >
          <Flex class='logo' justifyContent={'center'} zIndex={3} height={100}>
            <Image
              height='90px'
              src={roehilllogo}
              alt='logo'
              marginLeft={status ? '180%' : 0}
              marginTop={status ? '20px' : 0}
            />
          </Flex>
          <Flex
            justifyContent={'center'}
            zIndex={2}
            height='540px'
            direction={'row'}
            marginTop={'-50'}
          >
            <Image
              src={roehill}
              alt='rs'
              className='loginimage'
              style={{ width: '100%' }}
            />
          </Flex>
          <Flex
            className='innerbox'
            alignSelf={'center'}
            justifyContent={'center'}
            width={'50%'}
            marginTop={'-80'}
          >
            <Text className='innertext' TextAlign={'center'}>
              INDULGENTLY REFRESHING
            </Text>
          </Flex>
        </Flex>
        <Flex marginTop={'25'}>
          <Button
            onClick={handleDisplay}
            width={80}
            display={status ? 'none' : 'block'}
            className='loginbutton textlogin glow-on-hover'
            height='278px'
            marginLeft={'-10'}
          >
            L<br></br>O<br></br>G<br></br>I<br></br>N
          </Button>

          <Flex
            class='whitebox'
            width={status ? '500px' : '0px'}
            display={status ? 'block' : 'none'}
            height='475px'
            marginTop='7.5%'
            backgroundColor='white'
            borderRightRadius={15}
            style={{ transition: 'width 1s' }}
          >
            {/* Login form begins */}
            <Button
              left={'90%'}
              display={status ? 'block' : 'none'}
              mt={'8'}
              bg={'transparent'}
              border={'none'}
              onClick={handleClose}
            >
              <IoMdCloseCircleOutline size={'28px'} />
            </Button>
            <Flex
              marginTop='18%'
              display={status ? 'block' : 'none'}
              direction={'column'}
              justifyContent={'center'}
              ml={'25%'}
            >
              <Text
                display={status ? 'block' : 'none'}
                textAlign={'center'}
                fontWeight={'bold'}
                ms={'-120'}
                fontSize={'30px'}
                mb={35}
              >
                LOGIN
              </Text>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl mb={40}>
                  <Input
                    id='email'
                    type='email'
                    name='email'
                    height={35}
                    w={'70%'}
                    {...register('email')}
                    display={status ? 'block' : 'none'}
                    placeholder='Email'
                    borderRadius='11px'
                  />
                  {errors && errors.email && (
                    <FormHelperText
                      color='red'
                      className='error2'
                      alignSelf={'self-start'}
                      mt={3}
                    >
                      {errors.email.message && errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl mb={50}>
                  <InputGroup>
                    <Input
                      id='password'
                      type={show ? 'text' : 'password'}
                      name='password'
                      height={35}
                      w={'70%'}
                      {...register('password')}
                      display={status ? 'block' : 'none'}
                      placeholder='Password'
                      borderRadius='11px'
                    />
                    <InputRightElement
                      display={status ? 'block' : 'none'}
                      position={'absolute'}
                      left={'58%'}
                      fontSize={20}
                    >
                      <Button
                        onClick={handleClick}
                        background={'none'}
                        border={'none'}
                      >
                        {show ? '🙊' : '🙈'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  {errors && errors.password && (
                    <FormHelperText
                      color='red'
                      className='error2'
                      alignSelf={'self-start'}
                    >
                      {errors.password.message && errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Flex ms={'20%'} mt={50}>
                  <Button
                    type='submit'
                    height={35}
                    p={4}
                    display={status ? 'block' : 'none'}
                    width={'40%'}
                    borderRadius='11px'
                    className='loginsmallbutton'
                  >
                    Login
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Flex>

        {/* Login form ends */}
      </Flex>
      {/* <Button onClick={handleDisplay} display={status ? 'none' : 'block'} className='loginbutton  glow-on-hover' marginTop='15%' width='117px' height='278px' position='absolute' marginLeft='70%'><Text className='textlogin'>L<br></br>O<br></br>G<br></br>I<br></br>N</Text> </Button> */}
      {/* </Box> */}
    </Flex>
  );
};

export default Login;

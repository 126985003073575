/*eslint-disable*/
import React from 'react';
import { Flex, Link, List, ListItem, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function Footer(props) {
  // const linkTeal = useColorModeValue('teal.400', 'red.200');=
  return (
    <Flex
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent='center'
      letterSpacing={2}
      px='30px'
      pb='20px'
      color={'#787878'}
      
    >
      &copy; {1900 + new Date().getYear()}, <Text as='span'>powered by</Text>,{' '}
      <List display='flex'>
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link
            color='#25aae1'
            href='https://www.trackgenesis.com/'
            fontWeight={'bold'}
            fontSize={'large'}
          >
            TrackGenesis
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}

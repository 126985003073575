import React, { useState } from 'react';
import { Text, Flex, Link, Box, SimpleGrid, Img, H1 } from '@chakra-ui/react';
import Card from '../../../components/Card/Card';
import leftArrow from '../../../assets/images/LeftArrow1.png';
import RightArrow from '../../../assets/images/RightArrow1.png';
import './process.css';
import 'animate.css';
import Video from './VideoPopUp/Videos';

function Process(props) {
  let product = props.product;
  let Arrow = product.processList.length - 1;

  return (
    <>
      {product.processList && (
        <Flex direction={'column'}>
          <Text className='aboutus'>Process</Text>
          <Flex className='line' mb={20}>
            {' '}
          </Flex>

          {product.processList.map((process, i) => (
            <Flex padding={10} key={i}>
              {i % 2 == 0 ? (
                <Card>
                  <Flex
                    direction={'row'}
                    data-aos='zoom-in'
                    data-aos-duration='3000'
                    data-aos-delay='300'
                    overflow={'hidden'}
                  >
                    <Flex
                      direction={'column'}
                      w={'100%'}
                      mr={'-25'}
                      mt={38}
                      justifyContent={'center'}
                      h={'100%'}
                    >
                      <Flex
                        borderRadius={100}
                        bg={'black'}
                        zIndex={'-2'}
                        justifyContent={'center'}
                        height={50}
                      >
                        <Text
                          color={'yellow'}
                          fontSize={20}
                          fontWeight={'extrabold'}
                        >
                          {process.processName}
                        </Text>
                      </Flex>
                      <Flex
                        justifyContent={'center'}
                        width={'90%'}
                        ml={'-1'}
                        pt={2}
                        direction={'column'}
                        height={'100%'}
                      >
                        <Text fontSize={16} fontWeight={'bold'} noOfLines={2}>
                          {process.description}
                        </Text>

                        <Flex justifyContent={'center'} width={'60%'} ms={18}>
                          <Video
                            link={process.videoLink}
                            description={process.description}
                            heading={process.processName}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      border={'solid'}
                      borderColor={'cyan.400'}
                      borderWidth={5}
                      borderRadius={100}
                      height={150}
                      width={150}
                    >
                      <Img
                        src={process.imageLink}
                        borderRadius={100}
                        height={150}
                        width={140}
                        alt='1stprocess'
                      />
                    </Flex>
                  </Flex>

                  {i != Arrow ? (
                    <Flex
                      data-aos='fade-down'
                      data-aos-duration='3000'
                      data-aos-delay='300'
                    >
                      <Img
                        src={leftArrow}
                        w={'30%'}
                        ms={'35%'}
                        style={{ transform: 'rotate(-23deg)' }}
                      />
                    </Flex>
                  ) : (
                    ''
                  )}
                </Card>
              ) : (
                <Card>
                  <Flex
                    direction={'row'}
                    data-aos='zoom-in'
                    data-aos-duration='3000'
                    overflow={'hidden'}
                  >
                    <Flex
                      border={'solid'}
                      borderColor={'cyan.400'}
                      borderWidth={5}
                      borderRadius={100}
                      height={150}
                      width={150}
                    >
                      <Img
                        src={process.imageLink}
                        borderRadius={100}
                        height={150}
                        width={140}
                        alt='1stprocess'
                      />
                    </Flex>
                    <Flex
                      direction={'column'}
                      width={'100%'}
                      ml={'-25'}
                      mt={38}
                      justifyContent={'center'}
                      h={'100%'}
                    >
                      <Flex
                        borderRadius={100}
                        bg={'black'}
                        zIndex={'-2'}
                        justifyContent={'center'}
                        height={50}
                      >
                        <Text
                          color={'yellow'}
                          fontSize={20}
                          fontWeight={'extrabold'}
                        >
                          {process.processName}
                        </Text>
                      </Flex>
                      <Flex
                        justifyContent={'center'}
                        width={'90%'}
                        ml={'28'}
                        pt={2}
                        direction={'column'}
                        height={'100%'}
                      >
                        <Text fontSize={16} fontWeight={'bold'} noOfLines={2}>
                          {process.description}
                        </Text>
                        <Flex justifyContent={'center'} width={'60%'}>
                          <Video
                            link={process.videoLink}
                            description={process.description}
                            heading={process.processName}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>

                  {i != Arrow ? (
                    <Flex
                      data-aos='fade-down'
                      data-aos-duration='3000'
                      data-aos-delay='300'
                    >
                      <Img
                        src={RightArrow}
                        w={'30%'}
                        ms={'20%'}
                        style={{ transform: 'rotate(15deg)' }}
                      />
                    </Flex>
                  ) : (
                    ''
                  )}
                </Card>
              )}
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
}

export default Process;

import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { uri } from '../../config';

const QR = ({ bId, pdtId, status, setDataUrl, pbcId }) => {
  // const [dataUrl,setDataUrl] =useState('')
  if (status) {
    const canvas = document.getElementById('url');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    setDataUrl(pngUrl);
  }
  return (
    <>
      {bId ? (
        <QRCode
          id='url'
          value={`${uri}/product?Id=${bId}&pId=${pdtId}`}
          size={status ? 250 : 86} // size is in px, //! 23 millimeter = 86.929133858 pixel (X)
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'L'} // L means less blocks in Qr good for small QR
          includeMargin={true}
          // renderAs={'svg'}
        />
      ) : (
        pbcId && (
          <QRCode
            id='url'
            value={`${uri}/product?bId=${pbcId}&pId=${pdtId}`}
            size={status ? 250 : 86} // size is in px, //! 23 millimeter = 86.929133858 pixel (X)
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'L'} // L means less blocks in Qr good for small QR
            includeMargin={true}
            // renderAs={'svg'}
          />
        )
      )}
      {/* <a onClick={downloadQR}> Download QR </a> */}
    </>
  );
};

export default QR;

import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AdminLayout from './layouts/Admin.js';
import Dashboard from './views/Dashboard/Dashboard.js';
import MasterFile from './components/ProductDetails/MasterFile.js';
import ProductList from './views/Dashboard/ProductList.js';
import TrackList from './views/Dashboard/TrackList.js';
import BcTrack from './views/Dashboard/BcTrack.js';
import TrackOrigin from './components/ProductDetails/TrackOrigin.js';
import TrackProduct from './components/ProductDetails/TrackProduct';
import MainPage from './views/Dashboard/MainPage.js';
import PrintQR from './views/Dashboard/PrintQR.js';
// import LatestLocation from 'components/ProductDetails/LatestLocation'
import CustomerHistoryList from './components/ProductDetails/CustomerHistoryList';
import AgeTracker from './views/Dashboard/AgeTracker';
import './App.css';
import Login from './views/Pages/Login.js';

function App() {
  const logData = localStorage.getItem('tg-token');

  return (
    <BrowserRouter>
      <Routes>
        <Route path='product' element={<MainPage />} />
        <Route path='age' element={<AgeTracker />} />

        {logData ? (
          <>
            (<Route index element={<Navigate replace to='admin/dashboard' />} />
            )
            <Route path='admin' element={<AdminLayout />}>
              <Route
                index
                element={<Navigate replace to='/admin/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='addProduct' element={<MasterFile />} />
              <Route path='addProduct/:id' element={<MasterFile />} />
              <Route path='productList' element={<ProductList />} />
              <Route path='trackList' element={<TrackList />} />
              <Route path='trackOrigin' element={<TrackOrigin />} />
              <Route path='trackProduct' element={<TrackProduct />} />
              <Route path='bcTrack' element={<BcTrack />} />
              <Route path='printQR' element={<PrintQR />} />
              <Route path='history' element={<CustomerHistoryList />} />
              {/* <Route path='latesthistory' element={<LatestLocation />} /> */}
            </Route>
            <Route
              path='/'
              element={<Navigate replace to='/admin/dashboard' />}
            />
          </>
        ) : (
          <>
            (<Route index element={<Login />} />)
            <Route
              index
              element={
                <Navigate replace to={logData ? 'admin/dashboard' : '/'} />
              }
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

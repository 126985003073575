import { Button, Flex, Text } from '@chakra-ui/react';
import Card from '../../components/Card/Card';
import React, { useEffect, useState } from 'react';
import { server } from '../../config';
import TableComponent from '../../components/Table';
import axios from 'axios';
import Swal from 'sweetalert2';
const CustomerHistoryList = () => {
  const [location, setLocation] = useState([]);
  const logData = localStorage.getItem('tg-token');
  let logDetails = JSON.parse(logData);
  const getLocation = async () => {
    await axios
      .get(`${server}/api/location/`, {
        timeout: 5000,
      })
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getLocation();
    return () => {
      console.log('This will be logged on unmount');
    };
  }, []);

  const dateFormatter = (value) => {
    console.log(value);
    if (value) {
      const date =
        value.slice(8, 10) + '/' + value.slice(5, 7) + '/' + value.slice(0, 4);
      return date;
    } else {
      return '-';
    }
  };
  //setting table content
  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        // accessor: (cell, row) => <ProductDetails state={cell} />,
        accessor: 'productId',
      },
      {
        Header: 'date',
        accessor: 'date',
        Cell: ({ value }) => dateFormatter(value),
      },
      {
        Header: 'Name',
        accessor: 'productName',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
      {
        Header: 'Sub Region',
        accessor: 'subRegion',
      },
      {
        Header: 'Country',
        accessor: 'countryCode',
      },
    ],
    []
  );

  return (
    <Flex pt={10}>
      <Card width='100%'>
        <Flex>
          {location?.length !== 0 ? (
            <TableComponent columns={columns} data={location} />
          ) : (
            <Text
              color={'#230B0C'}
              mt={'10%'}
              ml={'5%'}
              fontWeight={'bold'}
              fontSize={20}
            >
              No Produt Data Available.
            </Text>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default CustomerHistoryList;

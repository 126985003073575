/*eslint-disable*/
import { HamburgerIcon } from '@chakra-ui/icons';
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Stack,
  Text,
  Image,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import IconBox from '../Icons/IconBox';

import { Separator } from '../Separator/Separator';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import madLogo from '../../assets/images/roehilogo.png';

function Sidebar(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  let variantChange = '0.2s linear';
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { sidebarVariant } = props;
    // Chakra Color Mode
    let activeBg = useColorModeValue(
      ' #20a4f3',
      ' linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
    );
    let inactiveBg = useColorModeValue(
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)',
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
    );
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue(
      'linear-gradient(180deg,  #43C6AC 0%, #191654 100%)',
      'linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
    );
    let sidebarActiveShadow = '0px 7px 11px rgba(0, 0, 0, 0.04)';
    // Here are all the props that may change depending on sidebar's state.(Opaque or transparent)
    if (sidebarVariant === 'opaque') {
      activeBg = useColorModeValue(
        'linear-gradient(180deg, #43C6AC 0%, #191654 100%)',
        'linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
      );
      inactiveBg = useColorModeValue(
        'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)',
        'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
      );
      activeColor = useColorModeValue(
        'linear-gradient(180deg, #43C6AC 0%, #191654 100%)',
        'linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
      );
      inactiveColor = useColorModeValue(
        'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%))',
        'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
      );
      sidebarActiveShadow = 'none';
    }

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <>
            <Text
              color={activeColor}
              fontWeight='bold'
              mb={{
                xl: '12px',
              }}
              mx='auto'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
            >
              {document.documentElement.dir === 'rtl'
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
              alignItems='center'
              // boxShadow={sidebarActiveShadow}
              bg={activeBg}
              transition={variantChange}
              mb={{
                xl: '15px',
              }}
              mt={{
                xl: '10px',
              }}
              mx={{
                xl: 'auto',
              }}
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
              borderRadius='15px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.04)',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg='white'
                    color='teal.300'
                    h='30px'
                    w='30px'
                    me='12px'
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text
                  color={'white'}
                  my='auto'
                  fontSize='sm'
                  textShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
                >
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg='linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
              boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
              mb={{
                xl: '15px',
              }}
              mt={{
                xl: '10px',
              }}
              mx={{
                xl: 'auto',
              }}
              py='12px'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              borderRadius='15px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={'black'}
                    color='teal.300'
                    h='30px'
                    w='30px'
                    me='12px'
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={'whiteAlpha.800'} my='auto' fontSize='sm'>
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };
  const { logoText, routes, sidebarVariant } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  const mainText = useColorModeValue('gray.700', 'gray.200');
  let sidebarBg = 'none';
  let sidebarRadius = '0px';
  let sidebarMargins = '0px';
  if (sidebarVariant === 'opaque') {
    sidebarBg = useColorModeValue('white', 'gray.700');
    sidebarRadius = '16px';
    sidebarMargins = '16px 0px 16px 16px';
  }
  var brand = (
    <Box pt={'25px'} mb='12px' >
      <Link
        href='/admin/dashboard'
        display='flex'
        lineHeight='100%'
        mb='30px'
        fontWeight='bold'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'
      >
        <div className='bgLogo'>
          <Image src={madLogo} position={'relative'} top='0.5' alt='logo' />
        </div>
        <Text fontSize='large' pl={4} mt='3px' class='logoFont'>
          {logoText}
        </Text>
      </Link>
      <Separator></Separator>
    </Box>
  );

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: 'none', xl: 'block' }} position='fixed'>
        <Box
          bg='rgba(255, 255, 255, 0.18)'
          borderRadius='16px'
          boxShadow='0 4px 30px rgba(0, 0, 0, 0.1)'
          backdropFilter='blur(5.1px)'
          webkitBackdropFilter='blur(5.1px)'
          border='1px solid rgba(255, 255, 255, 0.04)'
          transition={variantChange}
          w='260px'
          maxW='260px'
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          h='calc(100vh - 32px)'
          ps='20px'
          pe='20px'
          m={sidebarMargins}
        >
          <Box>{brand}</Box>
          <Stack direction='column' mb='40px'>
            <Box>{links}</Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

// FUNCTIONS

export function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const mainPanel = React.useRef();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    // Chakra Color Mode
    const activeBg = useColorModeValue(
      ' linear-gradient(180deg, #43C6AC 0%, #191654 100%)',
      ' linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
    );
    const inactiveBg = useColorModeValue(
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)',
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
    );
    const activeColor = useColorModeValue(
      ' linear-gradient(180deg, #43C6AC 0%, #191654 100%)',
      ' linear-gradient(180deg, #43C6AC 0%, #191654 100%)'
    );
    const inactiveColor = useColorModeValue(
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)',
      'linear-gradient(180deg,  #000046 -40%, #1CB5E0 100%)'
    );

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.category) {
        var st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <>
            <Text
              color={activeColor}
              fontWeight='bold'
              mb={{
                xl: '12px',
              }}
              mx='auto'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
            >
              {document.documentElement.dir === 'rtl'
                ? prop.rtlName
                : prop.name}
            </Text>
            {createLinks(prop.views)}
          </>
        );
      }
      return (
        <NavLink to={prop.layout + prop.path}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg={activeBg}
              mb={{
                base: '25px',
                xl: '25px',
              }}
              mx={{
                xl: 'auto',
              }}
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              py='12px'
              borderRadius='15px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox bg='white' color='teal' h='30px' w='30px' me='12px'>
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my='auto' fontSize='sm'>
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize='initial'
              bg={inactiveBg}
              boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
              justifyContent='flex-start'
              alignItems='center'
              mb={{
                base: '25px',
                xl: '25px',
              }}
              mx={{
                xl: 'auto',
              }}
              py='12px'
              ps={{
                sm: '10px',
                xl: '16px',
              }}
              borderRadius='15px'
              _hover='none'
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent',
              }}
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={'black'}
                    color='teal'
                    h='30px'
                    w='30px'
                    me='12px'
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my='auto' fontSize='sm'>
                  {document.documentElement.dir === 'rtl'
                    ? prop.rtlName
                    : prop.name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      );
    });
  };
  const { logoText, routes, ...rest } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  const mainText = useColorModeValue('gray.700', 'gray.200');
  let hamburgerColor = useColorModeValue('white', 'white');
  if (props.secondary === true) {
    hamburgerColor = 'white';
  }
  var brand = (
    <Box pt={'35px'} mb='8px'>
      <Link
        href='/admin/dashboard'
        display='flex'
        lineHeight='100%'
        mb='30px'
        fontWeight='bold'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'
      >
        <div className='bgLogo'>
          <Image src={madLogo} position={'relative'} top='0.5' alt='logo' />
        </div>
        <Text fontSize='large' pl={4} mt='3px' class='logoFont'>
          {logoText}
        </Text>
      </Link>
      <Separator></Separator>
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // Color variables
  return (
    <Flex
      display={{ sm: 'flex', xl: 'none' }}
      ref={mainPanel}
      alignItems='center'
    >
      <HamburgerIcon
        color={hamburgerColor}
        w='18px'
        h='18px'
        ref={btnRef}
        colorScheme='teal'
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w='250px'
          maxW='250px'
          ms={{
            sm: '16px',
          }}
          my={{
            sm: '16px',
          }}
          borderRadius='16px'
          bg={
            'linear-gradient(180deg, rgba(19, 123, 123, 0.47) 0%, rgba(18, 19, 18, 0.4653) 100%)'
          }
        >
          <DrawerCloseButton
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW='250px' px='1rem'>
            <Box maxW='100%' h='100vh'>
              <Box>{brand}</Box>
              <Stack direction='column' mb='40px'>
                <Box>{links}</Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};
SidebarResponsive.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;

import React from 'react';
import './SplashScreen.css';
import Mainlogo from '../../../assets/images/roehilogo.png';
import { Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import 'animate.css';

function SplashScreen() {
  return (
    <Flex className='app-splash-screen'>
      <Flex className='app-splash-inner' mt={140}>
        <Flex className='app-logo'>
          <Image className='LogoDesign' src={Mainlogo} w={150} alt='' />
        </Flex>
        <Text
          color='white'
          m='10'
          className='logoText animate__animated animate__bounce animate__delay-2s'
          fontSize={30}
        >
          ROEHILL SPRINGS
        </Text>
        <Flex className='app-loader' mt={40}>
          <div className='loader'></div>
          {/* <ClipLoader className='appLoad' color='white'  size={50} /> */}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SplashScreen;

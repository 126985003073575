import { Flex, Link, Text, Td, Th, Table, Tr, Tbody } from '@chakra-ui/react';

import React from 'react';
import { RiExternalLinkFill } from 'react-icons/ri';

import { networkExplorer } from '../../../../config';

const BlockchainDetails = ({ blockNo, transactionHash }) => {
  return (
    <Flex direction={'column'}>
      <Text className='aboutus'>Blockchain Details </Text>
      <Flex className='line'> </Flex>

      {/* Product info component */}
      <Flex marginTop={'10%'} w={'100%'} justifyContent={'center'}>
        <Table
          variant='simple'
          boxShadow={'2px 4px 4px rgba(0, 0, 0, 0.5)'}
          w={'90%'}
        >
          <Tbody justifyItems={'center'} p={'10px'} border={'2px solid red'}>
            {blockNo?.map((block, index) => (
              <React.Fragment key={index}>
                <Tr justifyItems={'center'}>
                  <Th p={'10px'} bgColor={'#0F2369'} color='white' w={'50%'}>
                    Block no
                  </Th>
                  <Td p={'10px'} bgColor={'#E8E8E8'} color='black' w={'50%'}>
                    <Flex
                      as={Link}
                      direction={'row'}
                      w={'100%'}
                      color='black'
                      fontSize={18}
                      href={`${networkExplorer}${transactionHash[index]}`}
                      gap={8}
                      justifyContent={'center'}
                    >
                      {block}
                      <RiExternalLinkFill size='12%' />
                    </Flex>
                  </Td>
                </Tr>
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default BlockchainDetails;

import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/youtube';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { AiTwotoneFire, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaBookReader } from 'react-icons/fa';
import './video.css';
const Video = (prop) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = useRef();
  let link = prop.link;
  let description = prop.description;
  let heading = prop.heading;

  return (
    <>
      <Button
        background='linear-gradient(180deg, #FF850D 0%, rgba(255, 0, 96, 0.73) 100%)'
        boxShadow=' 0px 4px 5px 1px rgba(0, 0, 0, 0.34)'
        borderRadius='20px'
        pt={4}
        pb={4}
        pl={14}
        pr={14}
        color={'white'}
        fontWeight={'bold'}
        ref={btnRef}
        alignSelf={'center'}
        onClick={() => {
          onOpen();
        }}
      >
        <FaBookReader color='black' />
        READ MORE
      </Button>
      <Modal
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior='inside'
        isCentered
        allowPinchZoom={true}
        // size={'lg'}
        overflow={'auto'}
      >
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='auto'
          backdropInvert='25%'
          backdropBlur='4px'
        />
        <ModalContent mx={10} justifyContent={'center'}>
          <Button
            backgroundColor={'black'}
            boxShadow='0px 4px 12px 2px rgba(0, 0, 0, 0.5)'
            p={0}
            mb={3}
            color={'white'}
            borderRadius={100}
            alignSelf={'end'}
            size={'md'}
            // id='closeBut'
            // position={'absolute'}
            onClick={onClose}
          >
            <AiOutlineCloseCircle size={25} />
          </Button>
          {/* <ModalHeader> */}

          <Flex
            className='video'
            borderBottomLeftRadius={10}
            borderBottomRightRadius={10}
          >
            <ReactPlayer
              url={link}
              controls={true}
              loop={true}
              playing={true}
              width={'100%'}
              style={{ zIndex: '2' }}
              height='100%'
            />
            {/* test url: https://youtu.be/m2iCiUrR4Wk */}
          </Flex>
          {/* </ModalHeader> */}
          <ModalBody
            className='overflow-auto'
            bg={'white'}
            borderBottomLeftRadius={10}
            borderBottomRightRadius={10}
          >
            <Text
              textAlign={'left'}
              pl={20}
              fontWeight={'bold'}
              fontSize={'30px'}
            >
              {heading}
            </Text>
            <Flex
              justifySelf={'center'}
              style={{ textIndent: '15px' }}
              fontSize={18}
              m={15}
              textAlign={'justify'}
              padding={5}
            >
              {description}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default Video;
